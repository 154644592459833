import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import LowAuthGuard from './components/LowAuthGuard';
import HighAuthGuard from './components/HighAuthGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/401',
    component: lazy(() => import('src/views/errors/NotAuthorisedView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/adminLogin',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        guard: HighAuthGuard,
        path: '/create/products',
        component: lazy(() => import('src/views/product/ProductCreateView'))
      },
      {
        exact: true,
        guard: HighAuthGuard,
        path: '/create/restock',
        component: lazy(() => import('src/views/restock/RestockCreateView'))
      },
      {
        exact: true,
        guard: HighAuthGuard,
        path: '/products',
        component: lazy(() => import('src/views/product/ProductListView'))
      },
      {
        exact: true,
        guard: HighAuthGuard,
        path: '/settlement',
        component: lazy(() => import('src/views/settlement'))
      },
      {
        exact: true,
        guard: HighAuthGuard,
        path: '/analytics',
        component: lazy(() => import('src/views/analytics'))
      },
      {
        exact: true,
        guard: HighAuthGuard,
        path: '/customers',
        component: lazy(() => import('src/views/customer/CustomerListView'))
      },
      {
        exact: true,
        path: '/inventory',
        component: lazy(() => import('src/views/inventory/InventorySearchView'))
      },
      {
        exact: true,
        guard: LowAuthGuard,
        path: '/sales',
        component: lazy(() => import('src/views/sales/SalesListView'))
      },
      {
        exact: true,
        guard: HighAuthGuard,
        path: '/salesmanagement',
        component: lazy(() => import('src/views/salesManagement/SalesListView'))
      },
      {
        exact: true,
        guard: LowAuthGuard,
        path: '/delivery',
        component: lazy(() => import('src/views/sales/DeliveryListView'))
      },
      {
        exact: true,
        guard: HighAuthGuard,
        path: '/products/:productId',
        component: lazy(() => import('src/views/product/ProductDetailsView'))
      },
      {
        exact: true,
        guard: HighAuthGuard,
        path: '/customers/:customerId',
        component: lazy(() => import('src/views/customer/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/inventory/:productId',
        component: lazy(() =>
          import('src/views/inventory/InventoryDetailsView')
        )
      },
      {
        exact: true,
        guard: LowAuthGuard,
        path: '/sales/:invoiceId',
        component: lazy(() => import('src/views/sales/SalesDetailsView'))
      },
      {
        exact: true,
        guard: HighAuthGuard,
        path: '/salesmanagement/:invoiceId',
        component: lazy(() =>
          import('src/views/salesManagement/SalesDetailsView')
        )
      },
      {
        exact: true,
        guard: HighAuthGuard,
        path: '/restock/:restockId',
        component: lazy(() => import('src/views/restock/RestockDetailsView'))
      },
      {
        exact: true,
        guard: LowAuthGuard,
        path: '/quotation',
        component: lazy(() => import('src/views/quotation'))
      },
      {
        exact: true,
        guard: LowAuthGuard,
        path: '/deposit',
        component: lazy(() => import('src/views/deposit'))
      },
      {
        exact: true,
        guard: LowAuthGuard,
        path: '/return',
        component: lazy(() => import('src/views/return'))
      },
      {
        exact: true,
        guard: LowAuthGuard,
        path: '/sell',
        component: lazy(() => import('src/views/sell/DraftListVIew'))
      },
      {
        exact: true,
        guard: LowAuthGuard,
        path: '/sell/:draftId',
        component: lazy(() => import('src/views/sell/DraftDetailsView'))
      },
      {
        exact: true,
        path: '/',
        component: lazy(() => import('src/views/actions/ActionView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
