import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const LowAuthGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/adminLogin" />;
  }

  if (user.power === 'low' && user.role === 'Inventory Checker') {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
};

LowAuthGuard.propTypes = {
  children: PropTypes.node
};

export default LowAuthGuard;
