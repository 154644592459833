import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Typography,
  Hidden
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          backgroundColor: theme.palette.background.paper
        }
      : {})
  },
  toolbar: {
    minHeight: 64
  },
  logo: {
    width: 48,
    height: 48,
    marginRight: theme.spacing(2)
  }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Box
          display="flex"
          alignItems="center"
          component={RouterLink}
          to="/"
          style={{ textDecoration: 'none' }}
        >
          <Logo className={classes.logo} />
          <Hidden mdDown>
            <Box>
              <Typography variant="h5" color="textPrimary">
                漢華布業有限公司
              </Typography>
              <Typography variant="h5" color="textPrimary">
                HANN HWA FABRICS SDN BHD
              </Typography>
            </Box>
          </Hidden>
        </Box>
        <Box ml={2} flexGrow={1} />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
