import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const HighAuthGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/adminLogin" />;
  }

  if (user.power !== 'high') {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
};

HighAuthGuard.propTypes = {
  children: PropTypes.node
};

export default HighAuthGuard;
